div#menu-Container {
  position: absolute;
  right: 0;
  width: 450px;
  height: calc(100% + 30px);
  border-left: solid 2px #fff3;
  transition: .5s;
  transition-delay: .025s;
  z-index: 6;
}

#page-Content.sidebar-Hidden div#menu-Container {
  right: -450px;
}

.menu-Title-Style {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  padding: 0.3rem;
  background: var(--Brand-Colour-Secondary);
}

.menu-Title-Style>img {
  width: 32px;
  margin-left: 0.5rem;
}

.menu-Logo-Style {
  margin-left: 0rem;
  padding: 0;
  padding-top: 0.1rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENBold';
}

.menu-Button-Style {
  color: var(--Brand-Colour-White);
  font-family: 'SSENRegular';
  --border-color: var(--Brand-Colour-White);
  --border-style: solid;
  --border-width: 2px;
}

.ion-page {
  background-color: var(--Brand-Colour-Primary);
  overflow: visible !important;
}

ion-header {
  z-index: 0 !important;
}

.menu-contents {
  height: calc(100% - 75px);
  --background: var(--Brand-Colour-Primary);
}

.block-Card-Style {
  position: relative;
  display: flex;
  margin: 0.8rem;
  padding: 1rem;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 25px;
  background-color: var(--Brand-Colour-Secondary);
  border: solid #fff4 3px;
  box-shadow: 0 0 10px 0px #2a2a2a;
}

.block-Card-Content {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 0 1rem;
  font-size: 1rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENRegular';
  text-align: left;
}

.block-Card-Content ul {
  margin: 0;
  padding-left: 30px;
}

.horizontal_dotted_line {
  border-bottom: 2px dashed #ffff;
  display: flex;
  opacity: 0.6;
  margin: 1.2rem 3rem;
  display: none;
}

.data-Card-Style {
  position: relative;
  display: flex;
  padding: 0.6rem;
  flex-direction: column;
  gap: 0.2rem;
  border-radius: 25px;
  background-color: var(--Brand-Colour-Secondary);
  border: solid #fff4 3px;
  box-shadow: 0 0 10px 0px #2a2a2a;
  flex: 1;
}

.data-Cards-Style-Attempt {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 1rem;
  margin: 1rem;
  display: flex;
}



.note-Card {
  background: #3B6B8B;
  border: solid #9DB5C5 2px;
  border-radius: 15px;
}

ion-checkbox {
  --size: 32px;

  --checkbox-background-checked: var(--Brand-Colour-Secondary);
  padding: 6px;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: solid #fff4 3px;
  --checkbox-background: var(--Brand-Colour-Primary);
}

ion-datetime-button::part(native) {
  background-color: var(--Brand-Colour-Secondary);
  color: var(--Brand-Colour-White);
  font-family: 'SSENBold';
  font-size: 0.95rem;
}

ion-datetime-button::part(native):hover {
  background: var(--Brand-Colour-Secondary, #fff);
  opacity: 0.80;
  cursor: pointer;
}

.calendar-days-of-the-week {
  color: #000000;
}

:host .calendar-days-of-week {
  color: #000000;
}




.note-Section-Style {
  position: relative;
  display: flex;
  margin: 0.8rem;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 25px;
  border: solid #fff4 3px;
  box-shadow: 0 0 10px 0px #2a2a2a;
  text-align: left;
  width: 78%;
  height: 100px;
}

.note-Section {
  position: relative;
  display: flex;
  flex-direction: row;
}

.note-Delete-Button {
  width: 30px;

}

.note-Header {
  background: rgb(100, 161, 187);
  border-radius: 15px;
  border: 2px solid rgb(178, 208, 221);
  width: calc(100% + 6px);
  margin: 0;
  top: -3px;
  left: -3px;
}

.note-Header-Text {
  font-size: 1.5rem;
  position: relative;
  color: var(--Brand-Colour-White);
  font-family: 'SSENREGULAR';
  margin: 0.8rem;
  user-select: none;
}

.note-Header-Text span {
  flex: 1;
}

.note-Header-Text ion-icon:last-child {
  color: white;
  transition: all 0.25s ease 0s;
  transform: none;
  cursor: pointer;
}

.dropdown-Card-Style {
  position: relative;
  display: flex;
  margin: 1.2rem;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 15px 0 0;
  border-radius: 10px;
  background-color: var(--Brand-Colour-Secondary);
  border: solid #fff4 3px;
  box-shadow: 0 0 10px 0px #2a2a2a;
  color: var(--Brand-Colour-White);
  font-family: 'SSENBold';
  font-size: 1.3rem;
}

.dropdown-Card-Style select-icon {
  font-size: 1rem;
}

ion-item.select-interface-option {
  --background: var(--Brand-Colour-Secondary);
  color: white;
  font-family: 'SSENBold';
}

ion-list.sc-ion-select-popover-md {
  background: var(--Brand-Colour-Secondary);
  ;
}

.note-Addition-Button {
  height: auto;
  width: 90%;
  --background: var(--Brand-Colour-Secondary);
  --border-style: solid;
  --border-color: var(--Brand-Colour-White);
  --border-radius: 10px;
  --border-width: 2px;
  --padding-top: 1rem;
  --padding-bottom: 1rem;
  font-family: 'SSENBold';
}

.note-Container {
  display: none;
  justify-content: center;
  flex-direction: column;
}

.note-Content-Style {
  font-size: 10.5px;
  color: var(--Brand-Colour-White);
  font-weight: bold;
  font-family: 'SSENRegular';
}

.letter-Card-Style {
  padding: 2rem 0;
  margin: 0 1rem;
  background-color: var(--Brand-Colour-Primary-85);
  /* border: 2px solid rgb(255, 255, 255) !important; */
  border-radius: 25px;
}

.block-Letter-Text {
  text-align: center;
  font-size: 1.8rem;
  color: var(--Brand-Colour-White);
  font-weight: bold;
  font-family: 'SSENBold';
  padding: 0;
}


.block-Letter-Text-Smaller {
  text-align: center !important;
  font-size: 2rem;
  color: var(--Brand-Colour-White);
  font-weight: bold;
  font-family: 'SSENBold';
  display: flex;
  justify-content: center;
}

.block-Card-Title {
  text-align: left;
  font-size: 1.5rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENBold';
  gap: 0.2rem;
  padding: 0 1rem;
}

.explanation-Card-Style {
  background-color: var(--Brand-Colour-Secondary);
  box-shadow: 0px 0px 10px -1px #181818;
  border-radius: 25px;
  padding: 1rem;
}

.explanation-Card-Title {
  padding: 0 1rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENREGULAR';
  text-align: center;
  font-size: 1.2rem;
  user-select: none;
}

.explanation-Card-Content {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 0 1rem;
  font-size: 1rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENRegular';
  text-align: left;
}

.information-Card-Style {
  background-color: #5b8e9f;
  box-shadow: 0px 0px 10px -1px #181818;
  border-radius: 25px;
  padding: 1rem;
}

.information-Card-Title {
  text-align: left;
  font-size: 1.5rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENBold';
  gap: 0.3rem;
  padding: 0 1rem;
}

.information-Card-Content {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 0 1rem;
  font-size: 1rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENRegular';
  text-align: left;
}

.information-Button-Style {
  height: auto;
  width: 100%;
  --background: var(--Brand-Colour-Primary-85);
  --border-style: solid;
  --border-color: var(--Brand-Colour-White);
  --border-radius: 5rem;
  --border-width: 2px;
  --padding-top: 1rem;
  --padding-bottom: 1rem;
  font-family: 'SSENBold';
}

.menu-Toggle {
  position: absolute;
  top: calc(50% - 30px);
  margin-left: -25px;
  width: 25px;
  height: 60px;
  background: var(--Brand-Colour-Primary);
  border: none;
  border-left: solid 2px #fff4;
  border-top: solid 2px #fff4;
  border-bottom: solid 2px #fff4;
  border-radius: 15px 0 0 15px;
  color: var(--Brand-Colour-White);
  transition: 0.1s;
}

.menu-Toggle:hover {
  background: var(--Brand-Colour-Primary-85);
  cursor: pointer;
}

.menu-Toggle ion-icon {
  position: relative;
  width: 1.2rem;
  height: 100%;
  margin-left: 0.2rem;
  transition: 0.25s;
}

.menu-Toggle ion-icon.rotate180deg {
  transform: rotate(180deg);
}

.native-textarea.sc-ion-textarea-md {
  height: 100px;
}

@media only screen and (max-width: 640px) {

  div#menu-Container {
    width: 100vw;
    border-left: 0;
  }

  #page-Content.sidebar-Hidden div#menu-Container {
    right: -100vw;
  }

  .modal-Create {
    width: 96% !important;
  }
  
  .modal-Edit {
    width: 96% !important;
  }
  
  
  .modal-Show {
    width: 96% !important;
  }

  .modal-Delete {
    width: 96% !important;
  }

}

.modal-Container {
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
  flex-wrap: wrap;
  z-index: 10;
}



.modal-Create {
  display: none;
  z-index: 1;
  background-color: var(--Brand-Colour-Primary);
  width: 800px;
  border-radius: 25px;
  flex-direction: column;
}

.modal-Edit {
  display: none;
  z-index: 1;
  background-color: var(--Brand-Colour-Primary);
  width: 800px;
  border-radius: 25px;
  flex-direction: column;
}


.modal-Show {
  display: none;
  z-index: 1;
  background-color: var(--Brand-Colour-Primary);
  width: 800px;
  border-radius: 25px;
  flex-direction: column;
}

.modal-Header {
  position: relative;
  color: var(--Brand-Colour-White);
  font-family: 'SSENBold';
  font-size: 1.3rem;
  margin: 0.5rem 1rem;
  display: flex;
  gap: 0.3rem;
  align-items: center;
}

.modal-Header .ion-icon {
  width: 25px;
  height: 25px;
}

.modal_content {
  background: var(--Brand-Colour-Primary);
  border-radius: 25px;
}

.modal-Delete {
  display: none;
  z-index: 1;
  background-color: var(--Brand-Colour-Primary);
  width: 800px;
  border-radius: 25px;
  flex-direction: column;
}


.modal-OutOfBounds {
  display: none;
  z-index: 1;
  background-color: var(--Brand-Colour-Primary);
  border-radius: 25px;
}

.modal-Information {
  display: none;
  position: fixed;
  z-index: 1;
  width: 900px;
  height: 50px;
  border-radius: 25px;
}


.note-Input {
  border-radius: 25px;
}

.Out_Of_Bounds_Card {
  background: #214E71;
  margin: 0;
  border-radius: 15px 15px 0 0;
}

.note-Layout-Input {
  position: relative;
  top: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

.note-Input-Line {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  color: #000000;
  margin-top: 25px;
}

.icon-Only {
  height: 30px;
  width: 30px;
}

.add-Note-Style {
  background: #214E71;
  border-radius: 15px 15px 0 0;
  width: 800px;
  right: 10px;
  top: -10px;
}


.note-Button-Green {
  --background: #87BB66;
  width: 220px;
  height: 50px;
  margin: 10px;
}

.note-Button-Red {
  --background: #DA402C;
  width: 220px;
  height: 50px;
  margin: 10px;
}

.note-Button-Delete {
  --background: #DA402C;
  width: 80px;
  height: 30px;
  margin: 7px;
}

.note-Button-Edit {
  --background: #dac02c;
  width: 80px;
  height: 30px;
  margin: 7px;
}

.note-Button-Show {
  --background: #4ab800c9;
  width: 80px;
  height: 30px;
  margin: 7px;
}



.note-Button-Understood {
  --background: #87BB66;
  height: 40px;
  width: 10rem;
  margin: 1rem 4rem;
}

.note-Delete-Edit-Button-Placement {
  display: flex;
}


.note-Button-Area {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
}




.note-Card-Style {
  position: relative;
  display: flex;
  margin: 0.8rem;
  padding: 1rem;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 15px;
  background-color: var(--Brand-Colour-Secondary);
  border: solid #fff4 3px;
  box-shadow: 0 0 10px 0px #2a2a2a;
  height: 50px;
}

.note-Card-Style-Small {
  display: flex;
  padding: 1rem;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  background-color: var(--Brand-Colour-Secondary);
  border: solid #fff4 3px;
  box-shadow: 0 0 10px 0px #2a2a2a;
  width: 50%;
  height: 78px
}

.note-Card-Style-Small-Datetime-Button {
  display: flex;
  padding: 1rem;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  background-color: var(--Brand-Colour-Secondary);
  border: solid #fff4 3px;
  box-shadow: 0 0 10px 0px #2a2a2a;
  width: 95%;
  height: 78px
}

.note-card-btn-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


.tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 74%;
  left: 72%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.note-card-btn-container .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.note-card-btn-container {
  width: 53%;
}

.add-Note-Dropdown-Style {
  display: flex;
  padding: 1rem;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  background-color: var(--Brand-Colour-Secondary);
  border: solid #fff4 3px;
  box-shadow: 0 0 10px 0px #2a2a2a;
  width: 50%;
  margin: 10px;
}

ion-popover {
  --width: 400px;
  left: 7px;
  top: 4px;

}


.note-Card-Content {
  color: var(--Brand-Colour-White);
  font-family: 'SSENBold';
  font-size: 0.95rem;
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
}

.note-Content-Text {
  color: var(--Brand-Colour-White);
  font-family: 'SSENRegular';
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  height: 100px;
  overflow: hidden;
}

.note-Content-Time {
  color: var(--Brand-Colour-White);
  font-family: 'SSENRegular';
  font-size: 12px;
}

.note-Content-Time-NonUser {
  color: var(--Brand-Colour-White);
  font-family: 'SSENRegular';
  font-size: 14px;
}

.note-Button-Placement {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.scenario-Style-Placement {
  display: flex;
  flex-direction: column;
  position: relative;
}

ion-segment-button {
  height: 30px;
  min-height: 30px;
  width: 45px;
  min-width: 45px;
  --indicator-color: var(--Brand-Colour-Secondary);
  --border-radius: 10px;
}

ion-segment {
  --background: #0003;
  border-radius: 12px;
}