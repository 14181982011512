.perm-and-fault-Label {
  color: var(--Brand-Colour-White);
  font-family: 'SSENBold';
  font-size: '0.95rem'
}

.modal-Container-Save {
  display: none;
  position: fixed;
  left: 0px;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
  flex-wrap: wrap;
  z-index: 10;
}

ion-datetime {
  --ion-color-step-650:  var(--Brand-Colour-White);
}