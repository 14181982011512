.note-Container {
    display: none;
    justify-content: center;
    flex-direction: column;
}

.note {
    background: #195176;
    border-radius: 15px;
    border: solid #8CA8A7 2px;
    height: 72px;
}

.note-Header {
    background: rgb(100, 161, 187);
    border-radius: 15px;
    border: 2px solid rgb(178, 208, 221);
    width: calc(100% + 6px);
    margin: 0;
    top: -3px;
    left: -3px;
}

.note-Header-Text {
    font-size: 1.5rem;
    position: relative;
    color: var(--Brand-Colour-White);
    font-family: 'SSENREGULAR';
    margin: 0.8rem;
    user-select: none;
}

.note-Header-Text span {
    flex: 1;
}

.note-Header-Text ion-icon:last-child {
    color: white;
    transition: all 0.25s ease 0s;
    transform: none;
    cursor: pointer;
}

.icon-Only {
    height: 30px;
    width: 30px;
}

.note-Card {
    background: #3B6B8B;
    border: solid #9DB5C5 2px;
    border-radius: 15px;
}

.note-Content-Positioning {
    display: 'flex';
    flex-direction: 'row';
    width: '375px';
}

.note-Content-Text {
    color: var(--Brand-Colour-White);
    font-family: 'SSENRegular';
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    height: 100px;
    overflow: hidden;
}

.note-Button-Positioning {
    display: 'flex';
    flex-direction: 'row';
    align-items: "center";
}

.note-Delete-Edit-Button-Placement {
    display: flex;
}

.note-Button-Delete {
    --background: #DA402C;
    width: 80px;
    height: 30px;
    margin: 7px;
}

.note-Button-Edit {
    --background: #dac02c;
    width: 80px;
    height: 30px;
    margin: 7px;
}

.note-Button-Show {
    --background: #4ab800c9;
    width: 80px;
    height: 30px;
    margin: 7px;
}

.note-Content-Time {
    color: var(--Brand-Colour-White);
    font-family: 'SSENRegular';
    font-size: 15px;
}

.nonUser-Note-Content {
    display: 'flex'; 
    flex-direction: 'row';
}

.note-Content-Time-NonUser {
    color: var(--Brand-Colour-White);
    font-family: 'SSENRegular';
    font-size: 15px;
  }