.modal-Delete {
    display: none;
    z-index: 1;
    background-color: var(--Brand-Colour-Primary);
    width: 800px;
    border-radius: 25px;
    flex-direction: column;
}

.modal_content {
    background: var(--Brand-Colour-Primary);
    border-radius: 25px;
}

.add-Note-Style {
    background: #214E71;
    border-radius: 15px 15px 0 0;
    width: 800px;
    right: 10px;
    top: -10px;
}

.note-Card-Style-Small {
    display: flex;
    padding: 1rem;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    background-color: var(--Brand-Colour-Secondary);
    border: solid #fff4 3px;
    box-shadow: 0 0 10px 0px #2a2a2a;
    width: 50%;
    height: 78px
}

.note-Card-Content {
    color: var(--Brand-Colour-White);
    font-family: 'SSENBold';
    font-size: 0.95rem;
    display: flex;
    justify-content: center;
    padding: 0;
    align-items: center;
}

.note-Button-Placement {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.note-Button-Green {
    --background: #87BB66;
    width: 220px;
    height: 50px;
    margin: 10px;
}

.note-Button-Red {
    --background: #DA402C;
    width: 220px;
    height: 50px;
    margin: 10px;
}

