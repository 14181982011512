div#mapbox-Container {
    position: absolute;
    left: 0;
    width: calc(100% - 450px);
    height: 100%;
    transition: width .5s;
    transition-delay: .025s;

}

#page-Content.sidebar-Hidden div#mapbox-Container {
    width: 100%;
    transition: width 0s;
}

.mapboxgl-ctrl-geocoder {
    position: fixed !important;
    top: 80px !important;
    left: 20px !important;
    border-radius: 20px !important;
    width: 230px !important;
    min-width: 230px !important;
    z-index: 2;
}

.mapboxgl-ctrl-geocoder .suggestions {
    border-radius: 20px !important;
}

#filter-Button {
    position: absolute;
    right: 460px;
    display: none;
    flex-direction: column;
    align-items: end;
    z-index: 99;
    pointer-events: none;
    transition: .5s;
    transition-delay: .025s;
    z-index: 1;
}

#filter-Button .map-Button-Container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-self: end;
    gap: 0.8rem;
}

.sidebar-Hidden #filter-Button {
    right: 10px;
}



#map-Buttons {
    position: absolute;
    bottom: 32px;
    right: 460px;
    display: flex;
    flex-direction: column;
    align-items: end;
    z-index: 3;
    pointer-events: none;
    transition: .5s;
    transition-delay: .025s;
}

.sidebar-Hidden #map-Buttons {
    right: 10px;
}
.sidebar-Hidden #modal-Filter {
    right: 10px;
}

.key-overlay {
    position: absolute;
    z-index: 1000;
    padding: 0 0.25em;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 0 0.5em 0.5em 0;
    margin-left: 1.25em;
    margin-top: 1em;
    display: none;
}

#map-Buttons .map-Button-Container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-self: end;
    gap: 0.8rem;
}

.modal-Postcode {
    display: none;
    position: fixed;
    z-index: 1;
    background-color: var(--Brand-Colour-Primary);
    width: 740px;
    height: 220px;
    border-radius: 25px;
}

.modal-Filter {
    display: none;
    position: fixed;
    right: 458px;
    top: 72px;
    z-index: 1;
    background-color: var(--Brand-Colour-Primary);
    height: 360px;
    border-radius: 25px;
    width: 160px;
    animation-name: example;
    animation-duration: 0.35s;
}

@keyframes example {
  0%   {opacity: 0;}
  50%  {opacity: 0.5;}
  100% {opacity: 1;}
}

.filter-Header {
    position: relative;
    color: var(--Brand-Colour-White);
    font-family: 'SSENBold';
    font-size: 1.3rem;
    margin: 0.5rem 1rem;
    display: flex;
    gap: 0.3rem;
    align-items: center;
    width: 107px
}

.modal_content {
    background: var(--Brand-Colour-Primary);
    border-radius: 25px;
}

.mapboxgl-canvas-container {
    transition: 0.2s;

}

.mouse-Marker .mapboxgl-canvas-container {
    cursor: url("../../../public/assets/images/map_markers/VectorMisc.png") 15 55, auto !important;
}

.modal-Shown .mapboxgl-canvas-container {
    filter: blur(5px);
}


.mouse-Marker .markerMiscUser {
    background-image: url("../../../public/assets/images/map_markers/VectorMisc.png");
    width: 36px;
    height: 49px;
    cursor: url("../../../public/assets/images/map_markers/VectorMisc.png") 6 6, auto !important;
}


#filter-Button .map-Button-Label {
    background-color: var(--Brand-Colour-Secondary);
    padding: 0.4rem 0.7rem;
    border-radius: 1rem;
    box-shadow: 0px 2px 10px 0px #1818189e;
    font-size: 0.9rem;
    opacity: 0;
    transition: opacity 0.5s;
    white-space: nowrap;
}

#map-Buttons .map-Button-Label {
    background-color: var(--Brand-Colour-Secondary);
    padding: 0.4rem 0.7rem;
    border-radius: 1rem;
    box-shadow: 0px 2px 10px 0px #1818189e;
    font-size: 0.9rem;
    opacity: 0;
    transition: opacity 0.5s;
    white-space: nowrap;
}



@media (pointer: fine) {
    #map-Buttons ion-button:hover~.map-Button-Label {
        opacity: 1;
    }

    #filter-Button ion-button:hover~.map-Button-Label {
        opacity: 1;
    }
}

.key-Container {
    position: absolute;
    top: 52px;
    z-index: 1;
}

.key {
    position: absolute;
    top: 1em;
    left: 1em;
    width: 60px;
    height: 655px;
    z-index: 999;
    padding: 0.5em;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 0.5em;
}

.key-Letter {
    position: relative;
    top: 4px;
    padding: 3px;
    font-family: 'SSENRegular';
    color: #000;
    font-size: 20px;
}

.engineerOnSite {
    background-image: url("../../../public/assets/images/map_markers/engineerOnSite.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}

.plannedFaultMarker {
    background-image: url("../../../public/assets/images/map_markers/plannedOutage.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}

.unplannedFaultMarker {
    background-image: url("../../../public/assets/images/map_markers/unplannedOutage.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}


.markerMiscUser {
    background-image: url("../../../public/assets/images/map_markers/VectorMisc.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}

.markerPublicNote {
    background-image: url("../../../public/assets/images/map_markers/VectorMisc.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}
.markerPublicNoteSelected {
    background-image: url("../../../public/assets/images/map_markers/VectorMiscSelected.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}

.markerPartnersNote {
    background-image: url("../../../public/assets/images/map_markers/VectorMisc.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}

.markerPartnersNoteSelected {
    background-image: url("../../../public/assets/images/map_markers/VectorMiscSelected.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}

.markerSSENNote {
    background-image: url("../../../public/assets/images/map_markers/VectorMisc.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}

.markerSSENNoteSelected {
    background-image: url("../../../public/assets/images/map_markers/VectorMiscSelected.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}

.markerPublicNoteNonUser{
    background-image: url("../../../public/assets/images/map_markers/VectorMisc.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
    opacity: 0.50 !important;
}

.markerPartnersNoteNonUser{
    background-image: url("../../../public/assets/images/map_markers/VectorMisc.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
    opacity: 0.50 !important;
}

.markerSSENNoteNonUser{
    background-image: url("../../../public/assets/images/map_markers/VectorMisc.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
    opacity: 0.50 !important;
}


.markerPublicNoteNonUserSelected {
    background-image: url("../../../public/assets/images/map_markers/VectorMiscSelected.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}

.markerPartnersNoteNonUserSelected {
    background-image: url("../../../public/assets/images/map_markers/VectorMiscSelected.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}

.markerSSENNoteNonUserSelected {
    background-image: url("../../../public/assets/images/map_markers/VectorMiscSelected.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
}

.markerMiscNonUser {
    background-image: url("../../../public/assets/images/map_markers/VectorMisc.png");
    width: 36px;
    height: 49px;
    cursor: pointer;
    opacity: 0.50 !important;
}

.key-Block {
    position: relative;
    top: 8px;
    padding: 3px;
    width: 25px;
    height: 15px;
    border-radius: 0.5rem;
}

.key-Letter-And-Block {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid gray;
}

.key-Letter-Z {
    display: flex;
    flex-direction: row;
}

#filter-Button ion-button {
    min-width: 45px;
    min-height: 45px;
    --padding-end: 0;
    --padding-start: 0;
    --background: var(--Brand-Colour-Secondary);
    pointer-events: all;
    flex: 1;
}

#map-Buttons ion-button {
    min-width: 45px;
    min-height: 45px;
    --padding-end: 0;
    --padding-start: 0;
    --background: var(--Brand-Colour-Secondary);
    pointer-events: all;
    flex: 1;
}

.filter-Close-Button {
    position: relative;
    bottom: 15px;
    left: 60px;
    pointer-events: all;
}

.filter-Close-Button:hover {
    background: var(--Brand-Colour-Secondary, #fff);
    opacity: 0.80;
    cursor: pointer;
}



#map-Buttons .map-Button-Container:nth-child(2) ion-button {
    --background: var(--Brand-Colour-Yellow);
}

#map-Buttons .map-Button-Container:nth-child(3) ion-button {
    --background: var(--Brand-Colour-Violet);
}

#map-Buttons .map-Button-Container:nth-child(4) ion-button {
    --background: var(--Brand-Colour-Green);
}




@media only screen and (max-width: 640px) {

    div#mapbox-Container {
        width: 100%;
        transition: none;
    }

    .mapboxgl-ctrl-geocoder {
        width: calc(100vw - 40px) !important;
    }

    #filter-Button {
        right: calc(100vw + 10px);
    }

    #map-Buttons {
        right: calc(100vw + 10px);
    }



}

.mapboxgl-popup-close-button {
    display: block;
    color: #fff;
    top: -38px !important;
    right: 17px !important;
    font-size: 30px
}

.mapboxgl-popup-content {
    font-family: 'SSENBold';
    padding: 0;
    background: var(--Brand-Colour-Primary) !important;
    border-radius: 0px 0px 10px 10px !important;
    opacity: 0.8;
    width: 400px;
}

.mapboxgl-popup-content h3 {
    background: var(--Brand-Colour-Secondary);
    color: #fff;
    margin: 0;
    padding: 10px;
    font-weight: 700;
    position: absolute;
    top: -46px;
    left: 0;
    border-radius: 10px 10px 0 0;
    width: 100%
}

.mapboxgl-popup-content h4 {
    margin: 0;
    padding: 9px;
    font-weight: 400;
    color: #fff;
}

.mapboxgl-popup-content div {
    padding: 10px;
}

.mapboxgl-popup{
    max-width: 400px !important;
}

.mapboxgl-popup-anchor-top>.mapboxgl-popup-tip {
    border-bottom-color: var(--Brand-Colour-Primary) !important;
    bottom: 46px;
    position: relative;
}

.mapboxgl-popup-anchor-bottom>.mapboxgl-popup-tip {
    border-top-color: var(--Brand-Colour-Primary) !important;
}


.note-Card-Fault {
    display: flex;
    justify-content: center;
    background: #3B6B8B;
    border: solid #9DB5C5 2px;
    border-radius: 15px;
}

.note-Card-Reference {
    display: flex;
    justify-content: center;
    background: #3B6B8B;
    border: solid #9DB5C5 2px;
    border-radius: 15px;
}

.note-Card-Reference:hover {
    background: var(--Brand-Colour-Secondary, #fff);
    opacity: 0.80;
    cursor: pointer;
}

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }