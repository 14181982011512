.horizontal_dotted_line {
    border-bottom: 2px dashed #ffff;
    display: flex;
    opacity: 0.6;
    margin: 1.2rem 3rem;
    display: none;
}


.scenario-Planner-Style {
    text-align: center;
    position: relative;
    color: var(--Brand-Colour-White);
    font-family: 'SSENBold';
    font-size: 1.4rem;
    display: none;
}

.scenario-Planner-Style>span {
    user-select: none;
}

.scenario-Style-Placement {
    display: flex;
    flex-direction: column;
    position: relative;
}


.scenario-Planner-Section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.level-Buttons {
    display: none;
    flex-direction: column;
    align-items: center;
}

.scenario-Increment-Button {
    position: relative;

    width: 50px;
    height: 33px;
    background: #B684B9;
    border: none;
    border-left: solid 2px #fff4;
    border-top: solid 2px #fff4;
    border-right: solid 2px #fff4;
    border-bottom: solid 2px #fff4;
    border-radius: 15px 15px 0 0;
    pointer-events: none;
}

.scenario-Increment-Button:hover {
    background: var(--Brand-Colour-Secondary, #fff);
    opacity: 0.80;
    cursor: pointer;
}

.icon-Only {
    height: 30px;
    width: 30px;
}

.scenario-Reduction-Button {
    position: relative;

    width: 50px;
    height: 30px;
    background: #B684B9;
    border: none;
    border-left: solid 2px #fff4;
    border-top: solid 2px #fff4;
    border-right: solid 2px #fff4;
    border-bottom: solid 2px #fff4;
    border-radius: 0 0 15px 15px;
    pointer-events: none;
}

.scenario-Reduction-Button:hover {
    background: var(--Brand-Colour-Secondary, #fff);
    opacity: 0.80;
    cursor: pointer;
}

.scenario-Planner-Buttons {
    height: auto;
    width: 95%;
    padding: 10px;
    --background: #87BB66;
    --border-radius: 25px;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    font-family: 'SSENBold';
    position: relative;
}

.scenario-Clear-Buttons {
    height: auto;
    width: 95%;
    padding: 10px;
    --background: #DA402C;
    --border-radius: 25px;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    font-family: 'SSENBold';
    position: relative;
}

.time-Buttons {
    display: none;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.scenario-Info-Card {
    margin: 0.8rem;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 15px;
    background-color: var(--Brand-Colour-Secondary);
    border: solid #fff4 3px;
    box-shadow: 0 0 10px 0px #2a2a2a;
    height: 60px;
    width: 700px;
    padding: 5px;
    text-align: center;
    display: none;
    justify-content: center;
}

.data-Cards-Style-Attempt {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 1rem;
    margin: 1rem;
    display: flex;
}

.data-Card-Style {
    position: relative;
    display: flex;
    padding: 0.6rem;
    flex-direction: column;
    gap: 0.2rem;
    border-radius: 25px;
    background-color: var(--Brand-Colour-Secondary);
    border: solid #fff4 3px;
    box-shadow: 0 0 10px 0px #2a2a2a;
    flex: 1;
}

.modal-Scenario {
    display: none;
    z-index: 1;
    background-color: var(--Brand-Colour-Primary);
    width: 750px;
    height: 260px;
    border-radius: 25px;
}

.modal_content {
    background: var(--Brand-Colour-Primary);
    border-radius: 25px;
}

.scenario-Style {
    background: #214E71;
    border-radius: 15px 15px 0 0;
    width: 750px;
    right: 10px;
    top: -10px;
}

.modal-Header {
    position: relative;
    color: var(--Brand-Colour-White);
    font-family: 'SSENBold';
    font-size: 1.3rem;
    margin: 0.5rem 1rem;
    display: flex;
    gap: 0.3rem;
    align-items: center;
}

.note-Layout-Input {
    position: relative;
    top: 10px;
    padding-right: 20px;
    padding-left: 20px;
}

.scenario-Planner-Selection-Style-Stage {
    display: flex;
    margin: 0.8rem;
    padding: 1rem;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 18px;
    background-color: var(--Brand-Colour-Secondary);
    border: solid #ffff 3px;
    box-shadow: 0 0 10px 0px #2a2a2a;
    width: 480px;
    height: 50px;
    justify-content: space-around;
    color: var(--Brand-Colour-White);
}

.scenario-Buttons-Style {
    display: flex;
    padding: 5px;
    justify-content: center;
}

.note-Button-Green {
    --background: #87BB66;
    width: 220px;
    height: 50px;
    margin: 10px;
  }
  
  .note-Button-Red {
    --background: #DA402C;
    width: 220px;
    height: 50px;
    margin: 10px;
  }
